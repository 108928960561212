import React from 'react'
import { Link, graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import { Container } from '../elements'

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMdx.edges

    return (
      <Layout>
        <SEO title="All posts" />
        <section>
          <Container mt="5rem">
            <div
              css={`
                padding-top: 1.5rem;
                padding-bottom: 1.5rem;
                @media screen and (min-width: 550px) {
                  padding-top: 2rem;
                }
                @media screen and (min-width: 1024px) {
                  padding-top: 3rem;
                }
              `}
              className="mx-auto max-w-2xl py-6"
            >
              <h1
                css={`
                  font-size: 2rem;
                  margin-bottom: 2.5rem;

                  @media screen and (min-width: 550px) {
                    display: none;
                  }
                `}
                className="font-bold"
              >
                Blog
              </h1>
              {posts.map(({ node }) => {
                const title = node.frontmatter.title || node.fields.slug
                return (
                  <Link
                    to={node.fields.slug}
                    key={node.fields.slug}
                    css={`
                      position: relative;
                      border-bottom: 1px solid hsl(240, 7.1%, 94.5%);
                      padding-bottom: 2.5rem;
                      margin-bottom: 2.5rem;
                      margin-left: -2rem;
                      margin-right: -2rem;
                      padding-left: 2rem;
                      padding-right: 2rem;
                      display: block;
                      background-color: var(--background-color-primary);

                      @media screen and (min-width: 550px) {
                        padding: 3rem;
                        border: 0px none;
                        margin-bottom: 1.5rem;
                        margin-left: 0px;
                        margin-right: 0px;
                        transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1)
                            0s,
                          padding 250ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
                      }
                    `}
                    className="mb-8"
                  >
                    <h1 className="pb-2 text-xl font-semibold">{title}</h1>
                    <small className="pb-2">{node.frontmatter.date}</small>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: node.frontmatter.description || node.excerpt,
                      }}
                    />
                  </Link>
                )
              })}
            </div>
          </Container>
        </section>
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(sort: { fields: frontmatter___date, order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "DD MMMM YYYY", locale: "NL")
            title
          }
        }
      }
    }
  }
`
